<template>
  <v-card
    width="100%"
    outlined
    class="k-alert py-2"
    :style="{ 'border-color': type.color }"
  >
    <v-card-title
      v-if="title"
      class="text-body-1 py-0"
      :style="{ color: type.color }"
      >{{ title }}</v-card-title
    >
    <v-card-text class="flex-col text-body-2 py-0">
      <v-row no-gutters style="flex-wrap: nowrap">
        <!-- ícone -->
        <v-col v-if="!noIcon" class="flex-grow-0 flex-shrink-0">
          <v-icon left :color="type.color">{{ type.icon }}</v-icon>
        </v-col>

        <!--  -->
        <v-col
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 d-flex align-center"
        >
          <div :class="`d-flex align-center`" :style="{ color: type.color }">
            <slot></slot>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "k-alert",
  props: {
    color: { type: String, default: "primary" },
    icon: String,
    noIcon: Boolean,
    title: String,

    info: Boolean,
    warning: Boolean,
    error: Boolean,
    success: Boolean
  },

  computed: {
    type() {
      const type = {};
      if (this.color) type.color = this.color;
      else type.color = "primary";

      if (this.icon) type.icon = this.icon;
      else type.icon = "mdi-information-outline";

      if (this.info)
        return { color: "#00bcd4", icon: "mdi-information-outline" };

      if (this.warning) return { color: "orange", icon: "mdi-alert-outline" };

      if (this.error) return { color: "red", icon: "mdi-alert-outline" };

      if (this.success)
        return { color: "green", icon: "mdi-check-circle-outline" };

      return type;
    }
  }
};
</script>

<style scoped lang="scss">
.k-alert {
  --info: "#00bcd4";
  --warning: "#ff9800";
  --success: "#4CAF50";
  --error: "#F44336";
}

.info--text {
  color: var(--info);
}
.warning--text {
  color: var(--warning);
}
.success--text {
  color: var(--success);
}
.error--text {
  color: var(--error);
}
.info--border {
  border-color: var(--info);
}
.warning--border {
  border-color: var(--warning);
}
.success--border {
  border-color: var(--success);
}
.error--border {
  border-color: var(--error);
}
</style>
