
<!--
    Cria dialogs no padrão dos sistemas

    @example

    <KDialog
      title="Título do Dialog"
      :loading="loading"
      :visible="visible"
      showClose
      :actions="[
        { eventName:'click:add', label: 'Salvar', color: 'red', },
        { eventName:'click:edit', label: 'Editar', color: 'primary', }
      ]"
      @click:add="onClickAdd"
      @click:edit="onClickEdit"
      @click:close="onClickClose"
    >
    </KDialog>

    @param {Boolean} loading
      - Define se o dialog estará com o estado de carregando

    @param {Boolean} visible
      - Define se o dialog estará ou não estará aberto

    @param {Boolean} fullscreen
      - Define se o dialog será aberto em tela cheia

    @param {Boolean} showClose
      - Define se um botão padrão de fechar será exibido junto da barra de ações
      do rodapé.
      - Esta opção NÃO se refere ao botão existente no canto superior direito
      - Padrão true
      - Caso seja definido como false, ainda será possível adicionar um botão 
      por meio do objeto 'actions', explicado abaixo

    @param {String} title
      - Define o título da dialog

    @param {Object[]} actions
      - Array de objetos com as definições dos botões que será mostrados no
      rodapé da caixa de diálog.
      - Sub itens:
        -  eventName: é o nome do evento retornado quando clicar nos botões. Se não for informado, 
           será usada a informação de key no formato "click:key".
           Ex.: Para key = "add", o evento retornado ao clicar será "click:add"
        -  key: É a chave usada no loop. Se não for informada, é usado o label como chave
        -  label: O texto do botão
        -  color: A cor do botão. O padrão é o primary
        -  icon:  Ícone do botão. Se não tiver label, mostre só o botão em formato de icon
        -  iconAfter {Boolean}: Mostra o ícone depois do label

-->
<template>
  <v-row justify="center" color="primary">
    <v-dialog
      scrollable
      v-model="visible"
      :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
      persistent
      retain-focus
      :maxWidth="maxWidth"
      v-bind="$attrs"
    >
      <v-card class="k-dialog">
        <!-- Cabeçalho -->
        <v-card-title class="flex-nowrap">
          <span class="headline d-inline-block text-truncate">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="loading" @click.stop="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Loading -->
        <v-progress-linear
          :style="{ opacity: loading ? 1 : 0 }"
          indeterminate
        ></v-progress-linear>

        <!-- Divisão -->
        <v-divider />

        <!-- Conteúdo -->
        <v-card-text class="mt-3" ref="dialog_body">
          <slot></slot>
        </v-card-text>

        <!-- Botões de ação -->
        <v-divider />
        <v-card-actions>
          <!-- Botão padrão de fechar -->
          <v-btn
            v-if="showClose"
            color="grey"
            :disabled="loading"
            text
            @click.stop="close"
            >Fechar</v-btn
          >

          <v-spacer></v-spacer>

          <!-- Lista de botões personalizados -->
          <v-btn
            v-for="action in actions"
            :key="action.key || action.label"
            :color="action.color || 'primary'"
            :disabled="loading"
            :icon="action.icon && !action.label"
            text
            @click.stop="$emit(action.eventName || `click:${key}`)"
          >
            <!-- Ícone. Se não tiver label, ative o atributo icon. Se tiver label, ative o atributo left -->
            <v-icon
              v-if="action.icon && !action.iconAfter"
              :left="!!action.label"
            >
              {{ action.icon }}
            </v-icon>
            <!--  -->
            {{ action.label }}

            <!-- Ícone after -->
            <v-icon
              v-if="action.icon && action.iconAfter"
              :right="!!action.label"
            >
              {{ action.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    loading: Boolean,
    fullscreen: Boolean,
    title: String,
    maxWidth: { type: String, default: "600px" },
    showClose: { type: Boolean, default: true },
    actions: { type: Array }
  },

  watch: {
    visible() {
      this.scrollTop();
    }
  },

  methods: {
    close() {
      this.scrollTop();
      this.$emit("click:close");
    },

    scrollTop() {
      if (this.$refs.dialog_body) {
        this.$refs.dialog_body.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style>
</style>
