<template>
  <div class="d-flex flex-row flex-wrap">
    <!--  -->
    <!-- {{$vuetify.breakpoint.name}} -->
    <!-- <v-row> -->
    <!-- Seleciona arquivo pra upload -->
    <!-- <v-col cols="12" :xs="6" sm="4" md="1" xl="1"> -->
    <div :style="`width:${thumbSize}px`" class="mr-2 my-2">
      <v-responsive aspect-ratio="1">
        <v-card class="mx-auto pick-file" style="width:100%;height:100%" outlined aspect-ratio="1">
          <!-- <v-responsive aspect-ratio="1"> -->
          <label for="upload_file">
            <input
              @change="onFilesPicked"
              style="display:none"
              type="file"
              id="upload_file"
              :multiple="multiple"
              ref="upload_file"
              :accept="local_accept"
            />
            <v-icon size="40">{{icon}}</v-icon>
            <div class="font-weight-light" style="opacity:0.8;line-height:20px">{{text}}</div>
            <!-- <div class="caption font-weight-light" style="opacity:0.8;line-height:20px">{{hint}}</div> -->
          </label>
          <!-- </v-responsive> -->
        </v-card>
      </v-responsive>
    </div>
    <!-- </v-col> -->

    <!-- <v-col sm="6" md="2">
        <upload-card icon="mdi-plus" @click="()=>{}" />
    </v-col>-->

    <!-- Lista de arquivos pendentes -->
    <!-- <v-col v-for="(file,i) in pendents" :key="i" cols="12" xs="6" sm="4" md="3" xl="2"> -->
    <div :style="`width:${thumbSize}px`" class="mr-2 my-2" v-for="(file,i) in pendents" :key="i">
      <!-- Arquivo pendente -->
      <upload-card
        :size="file.size"
        :src="file.url"
        :state="file.state"
        :type="file.type"
        :name="file.name"
        :message="file.message"
        @click:remove="removePendent(i)"
      />
    </div>
    <!-- </v-col> -->
    <!-- </v-row> -->
  </div>
</template>

<script>
import KAlert from "@/components/KAlert";
import Vue from "vue";
import UploadCard from "./UploadCard";

export default {
  components: { KAlert, UploadCard },
  props: {
    title: String,
    abstract: String,
    id: Number,
    files: Array,
    allowPasteEvent: Boolean,
    accept: Array,
    // Aceita múltiplos arquivos
    multiple: { type: Boolean, default: true },

    icon: { type: String, default: "mdi-plus" },
    text: { type: String, default: "Adicionar Arquivos" },
    hint: {
      type: String,
      default:
        "Somente imagens PNG e JPG, com dimensões mínimas de 1080x1080 e tamanho máximo de 2MB"
    },
    // Tamanho padrão dos quadrados
    thumbSize: { type: [Number, String], default: 120 }
  },
  data() {
    return {
      // local_title: null,
      // local_abstract: null,
      //Arquivos que ainda não fizeram upload
      pendents: []
    };
  },

  watch: {
    local_url(val) {
      this.$emit("update:url", val);
    },
    local_title(val) {
      this.$emit("update:title", val);
    },
    local_abstract(val) {
      this.$emit("update:abstract", val);
    }
  },
  computed: {
    local_accept() {
      if (!this.accept || !this.accept.length) return "*/*";
      return this.accept.join(", ");
    }
  },
  methods: {
    /**
     * Realiza o upload dos arquivos pendentes
     */
    async uploadAll(galleryId) {
      try {
        const pendents = this.pendents
          .filter(file => file.state !== "success")
          .map((file, i) => this.upload(i, galleryId));
        let hasErrors = 0;
        const result = await Promise.all(pendents)
          .catch(error => {
            hasErrors++;
            // console.log("catch do promise.all", error);
            return pendents;
          })
          .then(pendents => {
            // console.log("entrou no then", pendents);
            return pendents;
          });
        // console.log("uploadall", result);

        return hasErrors;
      } catch (error) {
        this.$message.error("Ocorreu um erro durante o upload das imagens");
        return false;
      }
    },

    /**
     * Executa um upload com as informações passadas
     *
     * @param index {integer} Índice do arquivo na lista de pendentes
     * @param index {integer} ID da galeria
     */
    async upload(index, galleryId) {
      //
      const fileObject = this.pendents[index];

      try {
        // fileObject.state = "progress";
        Vue.set(this.pendents, index, { ...fileObject, state: "progress" });

        const result = await this.$store.dispatch("media/upload", {
          galleryId,
          file: fileObject.file
        });

        // console.log("success", result);

        // fileObject.state = "success";
        // Vue.set(this.pendents, index, fileObject);
        Vue.set(this.pendents, index, { ...fileObject, state: "success" });
        // this.fi

        return result;
      } catch (error) {
        // Modifica o array dos pendentes para demonstrar sucesso ou erro, o até mesmo progresso
        // fileObject.state = "error";
        // Vue.set(this.pendents, index, fileObject);
        Vue.set(this.pendents, index, {
          ...fileObject,
          state: "error",
          message: error.response.data.message
        });

        // console.log("error do catch", error.response.data.message);
        throw error.response.data.message;
      }
    },

    /**
     *
     */
    getPendents() {
      return this.pendents.filter(file => file.state !== "success");
    },

    /**
     * Executa ao perceber mudanças no input file
     */
    onFilesPicked(event) {
      const { files } = event.target;
      const filesList = Array.from(files) || []
      
      if (filesList.length) {
        filesList.forEach(file => this.fileEvaluate(file));
      }
    },

    /**
     * Avalia cada arquivo individualmente para
     */
    fileEvaluate(fileReaderObject) {
      // Inicia o objeto de leitura do arquivo
      var reader = new FileReader();

      // Inicia error como false
      let error = false;

      // Evento ao carregar o arquivo
      reader.onload = evt => {
        let file = {
          // CONFIGURAÇÕES COMUNS AOS ARQUIVOS INICIAIS E AOS ADICIONADOS

          // Pendente caso ainda não tenha feito o upload
          // pendent: true,
          // Extensão do arquivo
          extension: this.extension(fileReaderObject.name),
          // type: "audio",
          type: this.type(fileReaderObject.type).type,
          // mime
          mime: fileReaderObject.type,
          // Tamanho em bytes
          size: fileReaderObject.size,
          // Nome original do arquivo
          name: fileReaderObject.name,
          // Estado do arquivo: pendent, eror, success ou progress
          state: "pendent",
          // state: "success",
          unique: parseInt(Math.random() * 999),

          // Dados originais do arquivo. Preenchido caso ainda não tenha feito o upload
          file: fileReaderObject || null,
          url: evt.target.result
        };

        // Valida e se contiver erro, altera o valor de file para corrigir
        file = this.processValidation(file);
        // Adiciona
        this.pendents.push(file);
        this.$emit("picked:file", file);
        // console.log("fileReaderObject", this.pendents);
      };

      // Realiza a leitura do arquivo
      reader.readAsDataURL(fileReaderObject);
    },

    /**
     * Processa a validação
     */
    processValidation(file) {
      if (file) {
        const currentType = this.type(file.mime);
        if (!currentType || !currentType.type) {
          file.state = "error";
          file.message = "Só serão aceitos arquivos JPG e PNG";
          return file;
        }

        // if ()
      }

      return file;
    },

    /**
     * setState altera o estado de um arquivo, podendo alterar qualquer uma
     * das configurações, como state, message etc.
     * As opções aceitas em state são: success, progress, error, pendent
     */
    setState(file, payload) {
      try {
        const index = this.pendents.findIndex(
          pendent => pendent.unique === file.unique
        );

        // console.log("index", index);

        Vue.set(this.pendents, index, { ...file, ...payload });
      } catch (error) {
        throw error;
      }
    },

    // setSuccess(file) {
    //   try {
    //     this.setState(file, "finished");
    //   } catch (error) {
    //     throw error;
    //   }
    // },

    /**
     * Retorna a extensão de um arquivo
     *
     * @param {String} filename Nome do arquivo
     * @return {String} Extensão do arquivo ou null
     */
    extension(filename) {
      var ext = /\.([a-z0-9]+$)/i.exec(filename);
      return ext ? ext[1].toLowerCase() : null;
    },

    /**
     * Retorna o tipo de acordo com a relação, sendo que é necessário que a extensão
     * e o tipo mime sejam iguais
     * Este array precisa estar igual em UploadCard
     */
    type(mime) {
      // const ext = this.extension(filename);

      const types = [
        {
          type: "audio",
          extension: "mp3",
          mime: [
            "audio/mp3",
            "audio/mpeg",
            "audio/mpeg3",
            "audio/x-mpeg-3",
            "video/mpeg",
            "video/x-mpeg"
          ],
          icon: "mdi-music"
        },
        {
          type: "document",
          extension: "pdf",
          mime: ["application/pdf"],
          icon: "mdi-file-pdf"
        },
        {
          type: "image",
          extension: "jpg",
          mime: ["image/jpeg", "image/jpg"],
          icon: null
        },
        {
          type: "image",
          extension: "png",
          mime: ["image/png"],
          icon: null
        }
      ];

      const type = types.find(type => type.mime.includes(mime));
      // console.log("gettype", type);

      return type || {};
    },

    remove(file) {
      try {
        const index = this.pendents.findIndex(
          pendent => pendent.unique === file.unique
        );
        this.removePendent(index);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Remove uma imagem pendenove uma imagem pendente
     */
    removePendent(i) {
      // Vue.set(this.pendents, i, undefined);
      this.pendents.splice(i, 1);
    },

    /**
     * onPaste
     * Ativado ao colar um arquivo
     */
    onPaste(e) {
      if (!e) return;

      const files = e.clipboardData.files;

      // console.log("onPaste", files);

      if (files.length) {
        for (let file of files) {
          //
          this.fileEvaluate(file);
        }
      }
    },

    /**
     * Ativa todos os eventos
     */
    subscribeEvents() {
      // Ativa o evento ao colar
      const event = window.addEventListener("paste", this.onPaste);
      // console.log("subscribe");
    },

    /**
     * Desativa todos os eventos
     */
    unsubscribeEvents() {
      // Remove o evento ao colar
      window.removeEventListener("paste", this.onPaste);
      // console.log("unsubscribe");
    }
  }
};
</script>

<style scoped>
.pick-file label {
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  height: 100%;
  align-items: center;
  text-align: center;
}
.pick-file label:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.message {
  text-align: center;
}
.message .v-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
</style>