<template>
  <v-sheet
    color="transparent"
    :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
  >
    <v-row no-gutters>
      <v-col>
        <h2 v-if="showTitle" class="title font-weight-light">{{ title }}</h2>
        <UploadView
          v-model="files"
          ref="upload"
          @picked:file="onPickFile"
          :accept="accept"
          :multiple="multiple"
          :thumb-size="thumbSize"
          :icon="buttonIcon"
          :text="buttonText"
        />
      </v-col>
    </v-row>
    <div v-if="showUploadedFiles">
      <h2 class="title font-weight-light mb-2">Arquivos Enviados</h2>

      <k-alert v-if="!combinatedFiles.length && !loading"
        >Nenhum arquivo até o momento</k-alert
      >

      <!-- Lista de arquivos já enviados -->
      <div class="d-flex flex-row flex-wrap" v-if="combinatedFiles.length">
        <!-- <v-col cols="12" xs="6" sm="4" md="3" xl="2" v-for="(file,i) in combinatedFiles" :key="file.id"> -->
        <v-card
          class="mr-2 mb-2"
          @click.stop.prevent="onClickImage(file)"
          :style="`width:${thumbSize}px`"
          v-for="(file, i) in combinatedFiles"
          :key="file.id"
        >
          <k-image
            class="k-image"
            ref="image"
            width="100%"
            :max-attempts="10"
            height="100%"
            style="border: 1px solid #ccc; border-radius: 4px"
            :src="file.url"
          >
            <!-- Botão de atualizar imagem -->
            <v-tooltip bottom v-if="refreshButton">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mx-1 action-button"
                  color="white"
                  @click.stop.prevent="onRefreshImage(i)"
                  icon
                  style="background-color: rgba(0, 0, 0, 0.5)"
                >
                  <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Recarregar a imagem</span>
            </v-tooltip>

            <!-- Botão de remover -->
            <v-tooltip bottom v-if="deleteButton">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mx-1 action-button"
                  :loading="
                    state[file.id] && state[file.id].state === 'progress'
                  "
                  color="white"
                  @click.stop.prevent="onRemoveImage(file)"
                  icon
                  style="background-color: rgba(0, 0, 0, 0.5)"
                >
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Remover esta imagem</span>
            </v-tooltip>

            <!-- Botão de abrir em nova aba -->
            <v-tooltip bottom v-if="newPageButton">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mx-1 action-button"
                  color="white"
                  :href="file.url"
                  @click.stop.prevent="openURL(file.url)"
                  icon
                  style="background-color: rgba(0, 0, 0, 0.5)"
                >
                  <v-icon color="white">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Abre em nova aba</span>
            </v-tooltip>
          </k-image>
        </v-card>
      </div>
    </div>

    <!-- Botão FAB -->
    <!-- <FAB :visible="!loading" @click="add"></FAB> -->
  </v-sheet>
</template>

<script>
// Bibliotecas
import Vue from "vue";
// Externos
import KImage from "@/components/KImage";
import KAlert from "@/components/KAlert";
// Internos
import UploadCard from "./UploadCard";
import UploadView from "./UploadView";

export default {
  props: {
    // Arquivos iniciais
    initialFiles: { type: Array, default: () => [] },
    // Nome da chave inicialFiles que armazena a url do arquivo. O padrão é "url"
    // mas é possível mudar para outro valor caso ele esteja armazenado em outra chave
    // geralmente isso é usado para exibir as thumbs ao invés da url completa, evitando
    // de ficar baixando o arquivo completo ao invés de somente as thumbs dele.
    // initialFilesUrlKey: { type: String, default: "url" },
    // Upload automático?
    autoUpload: Boolean,
    // Endereço do store que vai processar o upload.
    // Ex.: store="profile/avatar"
    store: String,
    // URL que irá processa o upload. Esta configuração sobrescreverá a opção
    // de fazer o upload a partir do store.
    // url-upload="/profile/avatar"
    urlUpload: String,
    // URL que irá processar a remoção do arquivo. Esta configuração sobrescreverá
    // a opção de remoção a partir do store. Passe esse atributo como função para
    // receber os dados do item para conseguir montar a url mais facilmente
    // Ex.: :action-delete="(item) => `/constructions/files/${item.id}`"
    actionDelete: Function,
    // Endereço do store que vai processar a remoção de uma imagem
    storeRemove: String,
    // Mime-types aceitos
    accept: { type: Array, default: () => ["image/png", "image/jpeg"] },
    // Aceita múltiplos arquivos
    multiple: { type: Boolean, default: true },
    // Dados que serão enviados juntamente com o arquivo de upload
    mergeData: Object,
    // Nome do campo que conterá o arquivo no objeto que será enviado para
    // o store de upload
    fieldName: String,
    // íCone do botão
    buttonText: String,
    // Texto do botão
    buttonIcon: String,
    // Mostra um título
    showTitle: { type: Boolean, default: true },
    // Texto do título
    title: { type: String, default: "Upload de Arquivos" },
    // Exibe o botão de atualizar imagem?
    refreshButton: { type: Boolean, default: true },
    // Exibe o botão de abrir em nova página?
    newPageButton: { type: Boolean, default: true },
    // Exibe o botão de excluir?
    deleteButton: { type: Boolean, default: true },
    // Tamanho padrão dos quadrados
    thumbSize: { type: [Number, String], default: 120 },
    // Define se o arquivo que acabou de ter seu upload finalizado deve
    // ser inserido na lista de arquivos que já foram enviados. Esta
    // configuração é necessária pois alguns stores adicionam o arquivo
    // com upload finalizado ao store e o arquivo fica duplicado. Quando
    // esta configuração for true, é porque initialFiles é atualizado em
    // tempo real pelo store e não precisa receber dados do componente.
    insertUploadedFile: { type: Boolean, default: true },
    // Remove o arquivo da lista de arquivos para enviar após o upload ser concluído
    removeUploadedFile: { type: Boolean, default: false },
    // Exibe abaixo os arquivos que já foram feitos o upload e os
    // arquivos iniciais
    showUploadedFiles: { type: Boolean, default: true }
  },
  components: {
    // Externos
    KImage,
    KAlert,
    // Internos
    UploadCard,
    UploadView
  },

  data() {
    return {
      //
      files: [],

      //
      loading: false,
      isPasteEventActive: false,

      //
      state: {
        // 38: { state: "progress", message: "não possi" }
      }
    };
  },

  watch: {
    // files() {
    //   console.log("novo arquivo", this.files[this.files.length - 1]);
    // }
  },

  computed: {
    combinatedFiles() {
      // const combinated = this.initialFiles;
      // this.files.forEach((item,i) => {
      //   this.initialFiles.push()
      // })

      const combinated = [...this.initialFiles, ...this.files];
      // const ids = combinated.map(item => item.id);
      // return combinated.filter((item, i) => item.id === ids[i]);
      // // const uni = [...new Set(combinated.map(x => x.id))];

      // console.log(combinated);
      // console.log(uni);

      // return uni;
      return combinated;
    }
    /**
     * Alimenta os partidos de forma reativa
     */
    // files() {
    //   return this.$store.getters["frames/all"];
    // }
  },

  //
  methods: {
    /**
     * Upload
     */
    async upload(data) {
      try {
        // console.log("index.upload, data", data);

        this.$refs["upload"].setState(data, { state: "progress" });

        let upload;

        // Se for para fazer o upload pela api e não pelo store
        if (this.urlUpload) {
          const result = await this.$api.upload(
            // URL do upload
            this.urlUpload,
            // a chave file está dentro de data e contém o arquivo em si
            data.file,
            // Mescla as opções requeridas pelo $api.upload com as
            // opções enviadas pelo próprio componente na variável mergeData
            { ...this.mergeData, fieldName: this.fieldName }
          );
          upload = result.data;

          // console.log("upload, ", upload.data);
        } else {
          upload = await this.$store.dispatch(this.store, {
            file: data,
            ...this.mergeData
          });
        }

        this.$refs["upload"].setState(data, { state: "success" });
        // Removo o arquivo que deu certo o upload
        if (this.removeUploadedFile) this.$refs["upload"].remove(data);
        // Emito um evento para avisar que deu certo
        this.$emit("upload:success", upload);
        // Se for para iniciar à lista de prontos
        if (this.insertUploadedFile) this.files.push(upload);
        // this.$emit;
      } catch (error) {
        // console.log(error.response.data.message);
        console.log("catch upload()", error);

        this.$refs["upload"].setState(data, {
          state: "error",
          message: error.response.data.message
        });
      }
    },

    /**
     * Ao selecionar um arquivo, já faça o upload dele
     */
    async onPickFile(file) {
      try {
        // console.log("pickfile", file);
        if (this.autoUpload) {
          await this.upload(file);
        }
      } catch (error) {
        throw error;
      }
    },

    // togglePasteEvent() {
    //   if (this.isPasteEventActive) {
    //     this.$refs["upload"].unsubscribeEvents();
    //     this.isPasteEventActive = false;
    //   } else {
    //     this.$refs["upload"].subscribeEvents();
    //     this.isPasteEventActive = true;
    //   }
    // },

    /**
     *
     * Deve ser colocado no evento do dialog ao abrir, ou ao entrar na página
     * para ativar
     */
    async activatePasteEvent() {
      try {
        // console.log("tentou");
        await this.$nextTick();
        console.log("ativou activatePasteEvent");

        this.$refs["upload"].subscribeEvents();
      } catch (error) {
        console.log("erro e tentou de novo");

        this.activatePasteEvent();
      }
    },

    /**
     * Deve ser colocado no evento de sair da página, ou fechar o dialog ou
     * ter o componente destruído
     */
    async deactivatePasteEvent() {
      await this.$nextTick();
      // console.log("desativou");
      this.$refs["upload"].unsubscribeEvents();
    },

    /**
     * Processa o upload de todos os arquivos pendentes
     */
    async uploadAll() {
      try {
        // console.log(this.$refs["upload"].getPendents());

        // return;
        const pendents = this.$refs["upload"]
          .getPendents()
          .filter(file => file.state !== "success")
          .map((file, i) => this.upload(file));
        let hasErrors = 0;
        const result = await Promise.all(pendents)
          .catch(error => {
            hasErrors++;
            // console.log("catch do promise.all", error);
            return pendents;
          })
          .then(pendents => {
            // console.log("entrou no then", pendents);
            return pendents;
          });
        // console.log("uploadall", result);

        return hasErrors;
      } catch (error) {
        this.$message.error("Ocorreu um erro durante o upload das imagens");
        return false;
      }
    },

    /**
     * Ao clicar em atualizar a imagem
     */
    async onRefreshImage(i) {
      try {
        this.$refs["image"][i].refresh(true);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Ao clicar em uma imagem
     */
    onClickImage(file) {
      this.$emit("click:image", file);
      // console.log("oncliecj");
    },

    /**
     * Ao clicar em remover imagem que já está com upload feito
     */
    async onRemoveImage(file) {
      // console.log("file", file);
      try {
        Vue.set(this.state, file.id, { state: "progress" });
        // this.state[file.id] = { state: "progress" };
        if (this.actionDelete) {
          const urlDelete = this.actionDelete(file);
          await this.$api.delete(urlDelete);
          // return;
        } else {
          const result = await this.$store.dispatch(this.storeRemove, file);
        }

        console.log("onremove", file);

        // Verifica se o arquivo está incluso nos arquivos iniciais
        if (this.initialFiles.find(item => item.id === file.id)) {
          const index = this.initialFiles.findIndex(
            item => item.id === file.id
          );
          console.log("é um inicial", file.id, "index", index);
          this.$delete(this.initialFiles, index);
          this.$emit(
            "update:initialFiles",
            this.initialFiles.filter(item => item.id !== file.id)
          );
          // console.log("arquivo está nos iniciais", file);
        } else {
          const foundFile = this.files.findIndex(item => item.id === file.id);
          console.log("foundFile", foundFile);
          Vue.delete(this.files, foundFile);
          // console.log("arquivo não está nos iniciais", foundFile);
        }
        // console.log("ao apagar", result);
        // console.log("file.id", file.id);
        // console.log("sstore.files", this.$store.getters["biography/images"]);
        // this.initialFiles =
        // this.state[file.id] = { state: "success" };
      } catch (error) {
        // console.log("irrrrr;rrrra", error);

        // this.$message.error(error.response.data.message);
        this.$message.serverError(error);
        Vue.set(this.state, file.id, { state: "error", message: "" });
        // this.state[file.id] = { state: "error", message: "" };
        throw error;
      }
    },

    /**
     * Limpa os dados adicionados
     */
    clear() {
      this.files = [];
      // console.log("limpou dentro do componente");
    },

    /**
     * Ao clicar em abrir em uma nova url
     */
    openURL(url) {
      window.open(url);
    }
  },

  mounted() {}
};
</script>

<style scoped>
.action-button {
  opacity: 0;
}

.k-image:hover .action-button {
  opacity: 1;
}
</style>
