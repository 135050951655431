<!--
  Card que mostra o upload. Vou informar o tipo para mostrar o ícone ou a miniatura
  type="image"
  src="urldaimagem"

  type="audio" // Mostra o ícone de musica
  type="pdf"  // Mostra o ícone do pdf

  message="mensagem de erro"
  state="error, success, progress, pendent"

  show-remove // Mostra o botão de remover
  show-open   // Mostra o botão de abrirem outra aba

  @click:remove="()=>{}" //Ao clicar em remover
  >

  Mostra o ícone ao meio. uma mensagem embaixo dele e as ações embaixo de tudo

-->
<template>
  <v-responsive aspect-ratio="1" :width="width">
    <v-card
      :width="width"
      :height="width"
      outlined
      :style="{backgroundColor: color, backgroundImage:'url('+ src +')', 'background-size':'cover', backgroundPosition:'center'}"
      class="uc-card d-flex justify-end align-center flex-column"
      :class="{'error--text':state==='error'}"
    >
      <div class="uc-content" :class="{'is-image': type === 'image'}">
        <!-- Ícone -->
        <div class="uc-icon">
          <v-icon size="36" :color="state==='error'?'error':'white'">{{local_icon}}</v-icon>
        </div>

        <!-- Mensagem de erro -->
        <div class="uc-message error" v-if="state==='error'">
          <p class="pa-1 ma-0 white--text caption">{{message}}</p>
        </div>

        <!-- Nome do arquivo -->
        <div class="uc-name text-truncate white--text">{{name}}</div>

        <!-- Rodapé -->
        <v-card-actions class="pb-0 uc-footer">
          <v-btn color="white" icon @click="onRemoveClick" v-if="showDelete">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn color="white" icon @click="onOpenInNewClick(src)" v-if="showOpen && src">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <!-- <v-btn color="white" icon @click="onRefresh(src)" v-if="showRefresh && src">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>-->
          <v-spacer></v-spacer>
          <div class="caption uc-size white--text">{{local_size}}</div>
        </v-card-actions>
      </div>
      <v-progress-linear indeterminate v-show="state==='progress'"></v-progress-linear>
    </v-card>
  </v-responsive>
</template>

<script>
export default {
  name: "upload-card",
  props: {
    /**
     * Tipo do card
     * Pode ser audio, document ou image.
     */

    type: {
      type: String,
      validator: function (value) {
        return ["audio", "document", "image"].includes(value);
      },
    },

    /**
     * Tamanho em bytes
     */
    size: {
      type: [String, Number],
      default: null,
    },

    /**
     * Mensagem de erro
     */
    message: { type: String, default: null },

    /**
     * Url da imagem
     * Precisa ser informada se for imagem. A imagem não será exibida se não for
     */
    src: {
      type: String,
      default: null,
    },
    /**
     * Nome do arquivo
     */
    name: String,

    /**
     * Estado do card. Sempre inicia como pendent e muda dependendo das variações
     */
    state: { type: String, default: "pendent" },

    /**
     * Mostra o botão de delete?
     */
    showDelete: { type: Boolean, default: true },

    /**
     * Mostra o botão de abrir em nova aba?
     */
    showOpen: { type: Boolean, default: false },

    /**
     * Mostra o botão de abrir em nova aba?
     */
    // showRefresh: { type: Boolean, default: false },

    /**
     * Cor de fundo
     */
    color: { type: String, default: "rgba(0,0,0,.4)" },

    /**
     * Tamanho do card
     */
    width: [Number, String],
  },
  data: () => ({}),
  watch: {
    // src() {
    //   console.log("trocou a url");
    // }
  },

  computed: {
    /**
     * Se o estado for success ou erro, mostre os ícones correspondentes.
     * Se o tipo não for imagem, mostre o ícone correspondente.
     * Se for imagem, não mostre
     */
    local_icon() {
      if (this.icon) return this.icon;
      // Se for erro ou sucesso, mostre os ícones corretos
      if (this.local_state === "error") return "mdi-alert";
      else if (this.local_state === "success") return "mdi-check";
      // Se for imagem, nao retorne icone.
      if (this.type === "image") return null;
      // Se não for nada acima, retorne o ícone correto
      if (this.local_accepted && this.local_accepted.icon)
        return this.local_accepted.icon;
      return null;
    },

    /**
     * SRC da imagem
     */
    // local_src() {
    //   console.log("trocou");

    //   return !this.chiba ? this.src : null;
    // },

    /**
     * Retorna o estado local de acordo com o que for recebido
     * Se state estiver preenchido, use-o
     */
    local_state() {
      if (this.state) return this.state;
      else return "pendent";
    },

    /**
     * Retorna qual os dados de acordo com o tipo
     * Esse array precisa estar igual em UploadView
     */
    local_accepted() {
      const accepted = [
        {
          type: "audio",
          extension: "mp3",
          mime: [
            "audio/mp3",
            "audio/mpeg",
            "audio/mpeg3",
            "audio/x-mpeg-3",
            "video/mpeg",
            "video/x-mpeg",
          ],
          icon: "mdi-music",
        },
        {
          type: "document",
          extension: "pdf",
          mime: ["application/pdf"],
          icon: "mdi-file-pdf",
        },
      ];
      return accepted.find((item) => item.type === this.type) || null;
    },

    /**
     * Devolve o tamanho formatado
     */
    local_size() {
      return this.filesize(this.size);
    },
  },
  methods: {
    onRemoveClick() {
      this.$emit("click:remove", this);
    },
    onOpenInNewClick(url) {
      window.open(url, "_blank");
    },
    // onRefresh() {
    //   // console.log("atualizar", this.src);
    //   // const src = this.src;
    //   // this.src = null;
    //   this.chiba = 1;
    //   // this.loading = true;
    //   // this.adada = 1;
    //   setTimeout(() => {
    //     console.log("pronto");
    //     // this.src = src;
    //     this.chiba = 0;

    //     // this.loading = false;
    //   }, 4300);
    // },

    /**
     * Converte bytes em um tamanho legível.
     *
     * @example
     * filesize(12354353) // 11 mb
     *
     * @param {Number} bytes
     * @return {String} Valor convertido para kb, mb etc
     */
    filesize(bytes) {
      if (!bytes) return;

      const map = [
        { min: 0, max: 1024, name: "bytes", divisor: 1 },
        { min: 1025, max: 1048576, name: "kb", divisor: 1024 },
        { min: 1048577, max: 1073741824, name: "mb", divisor: 1048576 },
      ];

      for (let item of map) {
        if (bytes >= item.min && bytes <= item.max) {
          return `${parseInt(bytes / item.divisor)} ${item.name}`;
        }
      }
    },

    /**
     * Testa se uma imagem está OK para ser exibida
     */
    isImageLoaded(url) {
      const image = new Image();
      image.onload = (e) => {
        console.log("carregou", e);
      };
      image.onerror = (e) => {
        console.log("error", e);
        this.reload(url);
      };

      image.src = url;
    },

    reload(url) {
      console.log("iniciou o reload");
      this.url = null;
      setInterval(() => {
        const img = new Image();

        this.url = url;
      }, 1000);

      this.url = null;
      setTimeout(() => {
        this.url = url;
        console.log("depois do reload");
        this.isImageLoaded(url);
      }, 1000);
    },
  },
  mounted() {
    // console.log("monado");
    // this.isImageLoaded(this.url);
  },
};
</script>

<style lang="scss" scoped>
.uc-message > p {
  border-radius: 5px;
}

.uc-card {
  height: 100%;
}

.uc-card > .uc-content {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  // min-height: 150px;
  width: 100%;
  // height: 100%;
}

.uc-card > .uc-content.is-image {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.uc-footer {
  width: 100%;
}

.uc-name {
  width: 100%;
  text-align: center;
}
</style>